<template lang="pug">
.vc-slder
  a-slider(v-model="num" :tooltipVisible="false" @afterChange="sliderAfterChange" @change="sliderChange")
  span.tip(v-if="!finish") 请按住滑块，拖动到最右边
  span.pass(v-if="finish") 验证通过
</template>

<script>
export default {
  name: 'vcSlider',
  data () {
    return {
      finish: false,
      num: 0
    }
  },
  methods: {
    sliderChange(val) {
      this.finish = val === 100
    },
    sliderAfterChange(val) {
      if (val !== 100) this.num = 0
    }
  }
}
</script>

<style lang="less">
.vc-slder {
  transition: background-color 0.3s;
  background-color: #91d5ff;
  height: 32px;
  position: relative;
  padding: 0 20px;

  &::before {
    transition: background-color 0.3s;
    background-color: #f5f5f5;
    content: '';
    display: block;
    height: 32px;
    position: absolute;
    left: 20px;
    right: 0;
    top: 0;
    z-index: 0;
  }

  &:hover {
    background-color: #69c0ff;
    
    .ant-slider-track {
      background-color: #69c0ff;
    }
    &::before {
      background-color: #e1e1e1;
    }
  }
  
  .ant-slider {
    height: 0px;
    padding: 0;
    margin: 0;
    
    .ant-slider-rail,
    .ant-slider-step {
      height: 0px;
    }
    
    .ant-slider-track,
    .ant-slider-handle {
      height: 32px;
      border-radius: 0;
    }
    
    .ant-slider-rail {}
    .ant-slider-track {}
    .ant-slider-step {}

    .ant-slider-handle {
      width: 40px;
      border: 1px solid #ccc;
      margin: 0;
      z-index: 99;
    }
  }
  
  .tip,
  .pass {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 12px;
    text-align: center;
    line-height: 32px;
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
  }

  .tip {
    background: -webkit-gradient(
      linear, left top, right top, color-stop(0,#4d4d4d), color-stop(.4,#4d4d4d), color-stop(.5,#fff),color-stop(.6,#4d4d4d),color-stop(1,#4d4d4d)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: slidetounlock 3s infinite;
    -webkit-text-size-adjust: none;
    pointer-events: none;
  }

  .pass { color:#fff; z-index: 100;}
}

@-webkit-keyframes slidetounlock {
	0% { background-position: -200px 0 }
	100% { background-position: 200px 0 }
}
</style>


