<template lang="pug">
.sign-container
  .sign-wrapper
    .sign-brand
      h1.text-center {{ ui.brand.text }}

    vc-sign(
      :sendSMS="sendSMS"
      :signSuccess="signSuccess"
      :gotoRegister="goRegister"
      :gotoForgot="goForgot")
    
    .sign-footer
      p.powered 版权所有 {{ ui.brand.text }}
</template>

<script>
import { mapState } from 'vuex'

import vcSign from '@/components/vcSign'

export default {
  name: 'SignView',
  components: {
    'vc-sign': vcSign
  },
  computed: {
    ...mapState({
      ui: state => state.ui
    }),
  },
  data() {
    return {}
  },
  methods: {
    signSuccess() {
      //- if (!this.$socket.connected) {
      //-   const token = sessionStorage.getItem('token');
      //-   this.$socket.io.opts.query = { token };
      //-   this.$socket.connect();
      //- }
      
      //- 跳转
      const path = this.$route.query.redirect ? this.$route.query.redirect : '/';
      this.$router.replace({ path });
    },
    
    // 跳转到注册页面
    goRegister() {
      this.$message.error('管理后台未开放注册');
    },

    // 跳转到忘记密码页面
    goForgot() {
      this.$message.error('遗失密码请联系开发人员');
    },
    
    // 发送验证码
    sendSMS() {
      this.$message.error('未开通手机短信发送业务');
    },

    // 登陆成功
    handleSigned() {
      //- if (!this.$socket.connected) {
      //-   const token = sessionStorage.getItem('token');
      //-   this.$socket.io.opts.query = { token };
      //-   this.$socket.connect();
      //- }
        
      const path = this.$route.query.redirect ? this.$route.query.redirect : '/';
      this.$router.replace({ path });
    }
  },
}
</script>

<style lang="less">
.sign-container {
  background-color: #004290;
  color: #fff;
  width: 100%; height: 100%;
  overflow: hidden;
  position: relative;
}
.sign-wrapper {
  font-family: 'PingFangSC-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 400px;
  margin: 0 auto;
  top: 108px;
  position: relative;

  .sign-brand {
    margin-bottom: 30px;
    h1 {
      color: #fff;
      font-size: 33px;
    }
  }

  .sign-footer {
    .powered {
      color: #999;
      text-align: center;
      font-size: 12px;
      padding: 25px 0;
      margin: 0;
    }
  }
  
  .extra-icon {
    text-align: center;
    color: #bbb;
    font-size: 42px;
    width: 56px; height: 56px;
    display: block;
    position: relative;

    .icon {
      font-size: 42px;
    }

    &::after {
      content: ''; display: block;
      pointer-events: none;
      position: absolute; bottom: 0; left: 0;
      width: 56px;
      height: 56px;
      border: 28px solid;
      border-color: transparent transparent #fff #fff;
      transition: all 0.3s;
    }

    &:hover {
      &::after {
        width: 30px;
        height: 30px;
        border-width: 15px;
      }
    }
  }
}

// 登录
.sign-content {
  background-color:#fff;
  width: 400px; min-height: 445px;
  position: relative;
  box-shadow: 0 2px 10px 0 rgba(57, 106, 255, 0.05);

  .sign-wrap { padding-left: 24px; padding-right: 24px;}
  
  // ant 覆写
  .ant-tabs-ink-bar { transition: all 0.15s ease-in-out;}
  .ant-form-item { margin-bottom: 25px;}

  .ant-input,
  .ant-btn,
  .ant-select-selection--single {
    height: 40px;
  }

  .ant-input-affix-wrapper { display: block;}

  form :not(.ant-input-group-wrapper) > .ant-input-group,
  form .ant-input-group-wrapper {
    display: block; top: 0;
  }

  .ant-select-selection__rendered { line-height: 38px;}

  .ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-input-group.ant-input-group-compact > *:last-child,
  .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  // 三方登录
  .sign-social {
    text-align: center;
    width: 200px; margin: 0 auto;
    margin-top: 35px;
    padding-bottom: 35px;

    .ant-divider {
      .ant-divider-inner-text {
        color: #999;
        font-size: 14px;
        padding: 0 10px;
      }
    }

    .btn-icon-link {
      font-size: 22px;
      display: inline-block;
      margin: 0 8px;
      color: #666;
      text-align: center;
      line-height: 30px;
      width: 30px;
      height: 30px;
      
      &.github-sign {
        &:hover {
          color: #fff;
          &::before {
            background-color: #000;
          }
        }
      }

      &.wechat-sign {
        &:hover {
          color: #fff;
          &::before {
            background-color: #58bc46;
          }
        }
      }

      &.qq-sign {
        &:hover {
          color: #fff;
          &::before {
            background-color: #3caae3;
          }
        }
      }

      &.weibo-sign {
        &:hover {
          color: #fff;
          &::before {
            background-color: #e90e24;
          }
        }
      }
    }

    .icon {
      position: relative;
      top: -1px;
    }
  }
}

.sign-static {
  .ant-tabs-bar {
    line-height: 57px;
    padding: 0 0 0 24px;
    margin: 0 0 30px 0;

    .ant-tabs-extra-content { line-height: 57px;}
    .ant-tabs-nav-container { line-height: 57px;}
    .ant-tabs-nav .ant-tabs-tab {
      font-size: 16px;
      padding: 0;
      margin: 0 25px 0 0;
    }
  }
}

.sign-wx {
  .ant-card-head { padding-right: 0; }
  .sign-qrcode {
    text-align: center;

    p { margin: 0;}

    .wx-qrcode {
      overflow: hidden;
      max-height: 226px;
      margin-bottom: 20px;
      img {
        width: 218px;
        border: 1px solid transparent;
      }
    }
  }
}

.sign-meta {
  font-size: 14px;
  display: flex; justify-content: space-between; align-items: center;
  .ivu-checkbox-wrapper { font-size:14px;}
}
</style>
