/**
 * Const
 */


export const OptionsCollapseMenus = [
  {
    label: 'setting.basic',
    value: 'basic'
  },
  {
    label: 'setting.theme',
    value: 'theme',
    childs: [
      {
        label: 'setting.theme_colors',
        value: 'theme_colors',
        childs: [
          {
            label: 'setting.theme_font_colors',
            value: 'theme_font_colors',
            childs: [
              {
                label: 'setting.theme_font_colors',
                value: 'theme_font_colors'
              }
            ]
          }
        ]
      },
      {
        label: 'setting.theme_background',
        value: 'theme_background'
      },
      {
        label: 'setting.theme_handle',
        value: 'theme_handle'
      },
      {
        label: 'setting.information',
        value: 'information'
      },
    ]
  },
  {
    label: 'setting.options',
    value: 'options'
  },
  {
    label: 'setting.staffs',
    value: 'staffs',
    childs: [
      {
        label: 'setting.theme_colors',
        value: 'theme_colors',
        childs: [
          {
            label: 'setting.theme_font_colors',
            value: 'theme_font_colors',
            childs: [
              {
                label: 'setting.theme_font_colors',
                value: 'theme_font_colors'
              }
            ]
          }
        ]
      },
      {
        label: 'setting.theme_background',
        value: 'theme_background'
      },
      {
        label: 'setting.theme_handle',
        value: 'theme_handle'
      },
      {
        label: 'setting.information',
        value: 'information'
      },
    ]
  },
  {
    label: 'setting.quotas',
    value: 'quotas'
  }
];

export const mobileAreaCodes = [
  { code: '+86', area: '中国' },
  { code: '+852', area: '中国香港' },
  { code: '+853', area: '中国澳门' },
  { code: '+886', area: '中国台湾' },
  { code: '+1', area: '美国' },
  { code: '+81', area: '日本' },
  { code: '+82', area: '韩国' },
  { code: '+60', area: '马来西亚' },
  { code: '+65', area: '新加坡' },
  { code: '+84', area: '越南' },
  { code: '+61', area: '澳大利亚' },
  // { code: '+1', area: '加拿大' },
  { code: '+44', area: '英国' },
  { code: '+33', area: '法国' },
  { code: '+7', area: '俄罗斯' },
  { code: '+49', area: '德国' },
  { code: '+66', area: '泰国' },
  { code: '+91', area: '印度' },
  { code: '+63', area: '菲律宾' },
  { code: '+55', area: '巴西' },
  { code: '+62', area: '印度尼西亚' },
  { code: '+39', area: '意大利' },
  { code: '+90', area: '土耳其' }
];

export const WelcomeSpeechs = [
  { label: '不显示', value: '-' },
  { label: 'AAA', value: 'AAA' },
  { label: 'BBB', value: 'BBB' }
];

export const Units = [
  { label: '%', value: '%'},
  { label: 'px', value: 'px'},
  { label: 'rem', value: 'rem'},
];

export const Positions = [
  { label: 'absolute', value: 'absolute'},
  { label: 'relative', value: 'relative'},
  { label: 'fixed', value: 'fixed'},
  { label: 'static', value: 'static'},
  { label: 'sticky', value: 'sticky'},
];

export const BackgroundColorTypes = [
  { label: 'pure', value: 'pure' },
  { label: 'gradient', value: 'gradient' },
];

// 背景渐变 类型
export const BackgroundGradientTypes = [
  { label: 'linear', value: 'linear' },
  { label: 'radial', value: 'radial' },
];

export const BackgroundPositionTypes = [
  { label: 'position', value: 'position'},
  { label: 'input', value: 'input'},
];

export const BackgroundRepeats = [
  { label: 'repeat', value: 'repeat'},
  { label: 'repeat-x', value: 'repeat-x'},
  { label: 'repeat-y', value: 'repeat-y'},
  { label: 'no-repeat', value: 'no-repeat'},
];

export const BackgroundPositions = [
  { label: 'center', value: 'center'},
  { label: 'top', value: 'top'},
  { label: 'bottom', value: 'bottom'},
  { label: 'left', value: 'left'},
  { label: 'right', value: 'right'}
];

export const BackgroundSizes = [
  { label: 'auto', value: 'auto'},
  { label: 'cover', value: 'cover'},
  { label: 'contain', value: 'contain'},
];

export const BackgroundAttachments = [
  { label: 'scroll', value: 'scroll'},
  { label: 'fixed', value: 'fixed'},
  { label: 'inherit', value: 'inherit'},
]