<template lang="pug">
.sign-content
  .sign-static(:bordered="false" v-if="signType === 'static'")
    a-tabs(v-model="tab_current" :animated="false")
      a-tab-pane.sign-wrap(key="tab1" tab="密码登录")
        
        a-form-model.sign-form(ref="formPSW" :model="formPSW" :rules="formValidatePSW")

          a-form-model-item(prop="account")
            a-input(v-model="formPSW.account" placeholder="账号/手机号/邮箱")
              a-icon(slot="prefix" type="user" style="color: rgba(0,0,0,.25)")

          a-form-model-item(prop="password")
            a-input(v-model="formPSW.password" placeholder="密码" type="password" :max-length="6")
              a-icon(slot="prefix" type="lock" style="color: rgba(0,0,0,.25)")
        
      a-tab-pane.sign-wrap(key="tab2" tab="短信登录")
        a-form-model.sign-form(ref="formSMS" :model="formSMS" :rules="formValidateSMS")
          a-form-model-item(prop="mobile")
            a-input-group(compact)
              a-select(v-model="select3" style="width: 30%" option-label-prop="value"
                :dropdownStyle="{ minWidth: '352px' }")
                a-select-option(:value="item.code" v-for="(item, index) in mobileAreaCodes" :key="index")
                  span {{ item.area }}
                  span(style="float:right;color:#ccc") {{ item.code }}
              a-input(v-model="formSMS.mobile" placeholder="手机号" :max-length="11" style="width: 70%")

          a-form-model-item
            vc-slider
            
          a-form-model-item.captcha(prop="captcha")
            a-input-group
              a-row(:gutter="8")
                a-col(:span="16")
                  a-input(v-model="formSMS.captcha" :max-length="6")
                    a-icon(slot="prefix" type="lock" style="color: rgba(0,0,0,.25)")
                a-col(:span="8")
                  a-button(type="info" block @click="handleSendSMS") 发送验证码

      a.extra-icon(slot="tabBarExtraContent")
        a-icon.icon(type="qrcode" @click="signType = 'wx'")

    .sign-wrap.p-b-30
      a-form-model-item
        a-button(block
          size="large"
          type="primary"
          :loading="loading"
          @click="handleSubmit") 登录
      
      .sign-meta
        a-checkbox(v-model="remember") 下次自动登录
        div
          a.login-form-forgot(@click="goForgot") 忘记密码
          a-divider(type="vertical")
          a(@click="goRegister") 注册
      
      .sign-social
        a-divider 使用第三方账号登录
        div
          a.btn-icon-link.github-sign(data-href="/thirdregister/github.do?appId=1006")
            a-icon.icon(type="github")
          a.btn-icon-link.wechat-sign(data-href="/thirdlogin/wechat.do?appId=1006")
            a-icon.icon(type="wechat")
          a.btn-icon-link.qq-sign(data-href="/thirdlogin/qq.do?appId=1006")
            a-icon.icon(type="qq")
          a.btn-icon-link.weibo-sign(data-href="/thirdlogin/wb.do?appId=1006")
            a-icon.icon(type="weibo")

  a-card.sign-wx(title="微信扫码登录" :bordered="false" v-if="signType === 'wx'")
    a.extra-icon(slot="extra")
      a-icon.icon(type="idcard" @click="signType = 'static'" style="position: relative; top: -5px; left: -3px;")
    .sign-qrcode
      .wx-qrcode
        img(src="/static/images/qrcode.jpg" alt="" style="opacity:0.4;")
      p.hint-text 扫码登录功能未接入
      //- p.hint-text 请使用微信扫描二维码登录
      p.hint-text {{ ui.brand.text }}
    .text-center.m-t-35.m-b-20
      a(@click="signType='static'") 密码登录
      a-divider(type="vertical")
      a(@click="goRegister") 注册新帐号
  
</template>

<script>
import { mapState, mapActions } from 'vuex'

import {
  mobileAreaCodes
} from '@/const'

import vcSlider from '@/components/vcSlider'
import { SIGNIN } from '@/store/modules/account'

export default {
  name: 'vcSign',
  components: {
    'vc-slider': vcSlider
  },
  props: {
    gotoRegister: {
      type: Function,
      default: null
    },
    gotoForgot: {
      type: Function,
      default: null
    },
    signSuccess: {
      type: Function,
      default: null
    },
    sendSMS: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      signType: 'static',
      tab_current: 'tab1',
      formPSW: {
        account: '',
        email: '',
        mobile: '',
        password: '', // '123456',
      },
      formSMS: {
        mobile: '',
        captcha: ''
      },
      formValidatePSW: {
        account: [
          { required: true, message: '账号/手机号/邮箱必须', trigger: 'blur' },
          // { type: 'string', min: 4, message: '账号长度最少4位', trigger: 'blur' },
          // { pattern: /^[a-zA-Z0-9_.]*$/, message: '只允许英文字母，数字和下划线(_)和点符号(.)', trigger: 'blur' }
        ],
        // email: [
        //   { required: true, message: '请填写邮箱地址', trigger: 'blur' },
        //   { type: 'email', message: '邮箱格式错误', trigger: 'blur' }
        // ],
        password: [
          { required: true, message: '密码必须', trigger: 'blur' },
          { type: 'string', min: 6, message: '密码长度最少6位', trigger: 'blur' }
        ]
      },
      formValidateSMS: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^(0|86|17951)?(1[3-9][0-9])[0-9]{8}$/, message: '手机号码格式错误', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '验证码必须', trigger: 'blur' },
        ]
      },
      mobileAreaCodes,
      remember: false,
      select3: '+86',
      loading: false
    }
  },
  computed: {
    ...mapState({
      ui: state => state.ui
    }),
    formReady() {
      // this.$refs['formPSW'].validate(valid => {
      //   if (valid) {
      //     this.$message.error('xxx')
      //   //   if (name === 'formAdd') {
      //   //     this.$http.post('user', this.formItem).then(res => {
      //   //       this.$message.success('添加成功!')
      //   //       this.$refs[name].resetFields()
      //   //       // ++this.total
      //   //       // this.get_date()
      //   //     }).catch(err => {
      //   //       this.$message.info(err.message)
      //   //     })
      //   //   }
      //   //   if (name === 'formEdit') {
      //   //     this.$http.put(`user/${this.formItem.id}`, this.formItem).then(res => {
      //   //       this.$message.success('修改成功!')
      //   //       this.$refs[name].resetFields()
      //   //       this.get_date()
      //   //     }).catch(err => {
      //   //       this.$message.info(err.message)
      //   //     })
      //   //   }
      //   } else {
      //     this.$message.error('ooo')
      //   }
      // })
      return Boolean(this.formPSW.account && this.formPSW.password)
    }
  },
  methods: {
    ...mapActions([ SIGNIN ]),

    // 跳转到注册页面
    goRegister() {
      if (this.gotoRegister) return this.gotoRegister();
      const { href } = this.$router.resolve({ name: `register` });
      window.open(href, '_blank');
    },

    // 跳转到忘记密码页面
    goForgot() {
      if (this.gotoForgot) return this.gotoForgot();
      const { href } = this.$router.resolve({ name: `lostPassword` });
      window.open(href, '_blank');
    },
    
    // 发送验证码
    handleSendSMS() {
      if (this.sendSMS) return this.sendSMS();
      if (this.formSMS.mobile) {
        this.$Modal.error({
          title: '验证码发送失败',
          content: '请稍后再试，或采用密码登陆'
        });
      }
    },

    // 登陆成功
    handleSigned() {
      if (this.signSuccess) return this.signSuccess();
      const path = this.$route.query.redirect ? this.$route.query.redirect : '/';
      this.$router.replace({ path });
    },

    /*********************************************************
     * @description 表单提交
     *********************************************************/
    async handleSubmit() {
      if (this.tab_current === 'tab2') return this.$message.error('未开通手机短信发送业务');

      const names = {
        tab1: 'formPSW',
        tab2: 'formSMS'
      };
      const name = names[this.tab_current];

      this.$refs[name].validate(async valid => {
        if (!valid) return this.$message.error('提交失败!');
        
        try {
          await this.$store.dispatch('account/signin', Object.assign(this.formPSW, { type: 'account' }));
          this.handleSigned();
        } catch (err) {
          this.$message.error(err.message);
        }
      });
    },
  },
  watch: {
  },
  mounted () {
    // F11 只能由用户触发
    // var el = document.documentElement
    // var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen
    // var wscript
    // if (typeof rfs !== 'undefined' && rfs) {
    //   rfs.call(el)
    //   return
    // }
    // if (typeof window.ActiveXObject !== 'undefined') {
    //   // eslint-disable-next-line
    //   wscript = new ActiveXObject('WScript.Shell')
    //   if (wscript) wscript.SendKeys('{F11}')
    // }
  }
}

// const validateAccount = (rule, value, callback) => {
//   if (!value) return callback(new Error('账号必须'))
//   if (!/^[a-zA-Z0-9_]*$/.test(value)) return callback(new Error('只允许英文字母，数字和下划线'))
//   // 模拟异步验证效果
//   // setTimeout(() => {
//   //   if (!Number.isInteger(value)) {
//   //     callback(new Error('Please enter a numeric value'));
//   //   } else {
//   //     if (value < 18) {
//   //       callback(new Error('Must be over 18 years of age'));
//   //     } else {
//   //       callback();
//   //     }
//   //   }
//   // }, 1000)
//   callback()
// }
// const validateRepassword = (rule, value, callback) => {
//   if (this.formPSW.password) {
//     if (value !== this.formPSW.password) {
//       callback(new Error('两次密码不一致'))
//     } else {
//       callback()
//     }
//   } else {
//     callback()
//   }
// }
</script>